import { Link } from "react-router-dom";
import {createInternalUrlWithParams, createSeoUrlWithParams, objectToHash} from "../../../../utils/common";
import { IsDom } from "@/utils/GlobalJavascriptFunction";
import { useTranslation } from "@i18n";

import stl from "./OfferCars.module.css";

const OfferCars = ({ offers, title, onClick }) => {
  const { t } = useTranslation(["web_home"]);
  return (
    <>
      <section
        className={[
          stl.offerCarsSection,
          stl.revampedContainer,
        ].join(" ")}>
        <div className="container">
          {title && (
            <h2
              className={[
                "big",
                stl.revampedMobileH,
                "container",
              ].join(" ")}>
              {title}
            </h2>
          )}
          <div
            className={[
              stl.offersBox,
              stl.revampedMobileBoxShap,
              stl.normalScrollMob,
              offers && offers.length <= 3 ? stl.has3Cars : "",
            ].join(" ")}>
            {offers.map((offer, i) => (
              <div key={i} onClick={() => onClick && onClick(offer.deal_name)}>
                <Link
                  rel="noindex, nofollow"
                  className={[stl.singleBoxOffer].join(" ")}
                  to={createSeoUrlWithParams(`/deal/${offer.id}${objectToHash({
                      referral_location: "Home",
                      browse_variant: "deals",
                      variant_value: offer?.id,
                  })}`)}>
                    <div className={stl.imageContainer}>
                        {IsDom && window.innerWidth >= 1025 ? (
                            <img
                                width={"274px"}
                                height={"227px"}
                                src={offer.box_img_desktop}
                                alt={offer?.deal_box_img_title}
                                loading="lazy"
                            />
                        ) : (
                            <img
                                width={"274px"}
                                height={"227px"}
                                src={offer.box_img_mobile}
                                alt={offer?.deal_box_img_title}
                                loading="lazy"
                            />
                        )}
                    </div>
                    <div>
                    <h3> {offer?.deal_box_img_title}</h3>
                    </div>
                </Link>
              </div>
            ))}
          </div>
          <a
            className={`${stl.viewAlloffers} whtBtn`}
            href={createInternalUrlWithParams("/offerspage")}>
            {t("شاهد جميع العروض", { ns: "web_home" })}
          </a>
        </div>
      </section>
    </>
  );
};

export default OfferCars;
